var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-review-container"},[_c('div',{staticClass:"pc"},[_c('div',{staticClass:"h6"},[_vm._v("후기")]),_c('div',{staticClass:"rate-container margin-top-24"},[_c('div',{staticClass:"body2 sub2"},[_vm._v("후기 "+_vm._s(_vm.allCount))]),_c('div',{staticClass:"flex-align",staticStyle:{"gap":"16px","margin-top":"16px"}},[_c('div',{staticClass:"flex-align",staticStyle:{"gap":"12px"}},[_c('star-rating',{attrs:{"rating":_vm.productRate,"readOnly":true,"active-color":"#FF6600","star-size":36,"increment":0.5,"padding":2,"star-points":[
              20,
              34,
              30,
              40,
              31,
              40,
              32,
              40,
              33,
              38,
              33,
              37,
              31,
              25,
              39,
              18,
              40,
              16,
              40,
              15,
              39,
              14,
              38,
              13,
              27,
              12,
              22,
              1,
              21,
              0,
              20,
              0,
              19,
              0,
              18,
              1,
              13,
              12,
              2,
              13,
              1,
              14,
              0,
              15,
              0,
              16,
              1,
              18,
              9,
              25,
              7,
              37,
              7,
              38,
              8,
              40,
              9,
              40,
              10,
              40,
              20,
              34 ],"show-rating":false}}),_c('div',{staticClass:"h4 main"},[_vm._v(" "+_vm._s(_vm.productRate)+" ")])],1)])]),_c('div',{staticClass:"review-tabs"},_vm._l((_vm.tabs),function(tab,idx){return _c('div',{key:'review-tab-' + idx,staticClass:"review-tab unselect",class:{
          active: _vm.activeTab === tab.value,
          'body4-bold main': _vm.activeTab === tab.value,
          'body4-medium sub3': _vm.activeTab !== tab.value,
        },on:{"click":function($event){return _vm.setActiveTab(tab.value)}}},[_c('div',[_vm._v(_vm._s(tab.name))]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(tab.count))])])}),0),(_vm.computedReviews && _vm.computedReviews.length > 0)?[_vm._l((_vm.computedReviews),function(review,idx){return _c('div',{key:'theme-review-' + idx,staticClass:"item-review"},[_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"flex-column",staticStyle:{"gap":"2px"}},[_c('div',{staticClass:"flex-align",staticStyle:{"gap":"4px"}},[_c('div',{staticClass:"body6 sub3"},[_vm._v(_vm._s(_vm.datesFormat(review.created_time, 'date_3')))]),_c('div',{staticClass:"line-bar"}),_c('div',{staticClass:"body6 sub3"},[_vm._v(_vm._s(_vm.authorFormat(review.author)))])]),_c('div',{staticClass:"flex-align",staticStyle:{"gap":"4px"}},[_c('star-rating',{attrs:{"rating":review.rate,"readOnly":true,"active-color":"#FF6600","star-size":16,"increment":0.5,"padding":1,"star-points":[
                  20,
                  34,
                  30,
                  40,
                  31,
                  40,
                  32,
                  40,
                  33,
                  38,
                  33,
                  37,
                  31,
                  25,
                  39,
                  18,
                  40,
                  16,
                  40,
                  15,
                  39,
                  14,
                  38,
                  13,
                  27,
                  12,
                  22,
                  1,
                  21,
                  0,
                  20,
                  0,
                  19,
                  0,
                  18,
                  1,
                  13,
                  12,
                  2,
                  13,
                  1,
                  14,
                  0,
                  15,
                  0,
                  16,
                  1,
                  18,
                  9,
                  25,
                  7,
                  37,
                  7,
                  38,
                  8,
                  40,
                  9,
                  40,
                  10,
                  40,
                  20,
                  34 ],"show-rating":false}}),_c('div',{staticClass:"body5-medium main"},[_vm._v(" "+_vm._s(review.rate)+" ")])],1)]),(review.imgs.length > 0)?_c('div',{staticClass:"flex-align drag-scroll margin-top-12"},_vm._l((review.imgs),function(img,idx){return _c('div',{key:'img-' + idx,staticClass:"unselect",staticStyle:{"display":"inline-block","margin-right":"4px"},on:{"click":function($event){return _vm.clickImg(review.imgs, idx)}}},[_c('e-image',{attrs:{"isBg":true,"isLazy":false,"img":img,"useThumb":true,"width":74,"useRatio":true,"imgRadius":8,"imageRates":{ ratio: '1:1' }}})],1)}),0):_vm._e(),(review.title && review.title !== '')?_c('div',{staticClass:"body6 sub3",staticStyle:{"margin":"12px 0"}},[_vm._v(" "+_vm._s(review.title)+" ")]):_vm._e(),_c('div',{staticClass:"body4 main"},[_vm._v(_vm._s(review.content))])])])}),_c('lp-paginate',{staticStyle:{"margin-top":"88px"},attrs:{"filtered":_vm.filtered},on:{"update:filtered":function($event){_vm.filtered=$event},"click":_vm.getData}})]:_c('div',{staticClass:"empty-view"},[_vm._v(_vm._s(_vm.emptyMessage))])],2),_c('div',{staticClass:"mobile",staticStyle:{"padding":"0 16px"}},[_c('div',{staticClass:"h6"},[_vm._v("후기")]),_c('div',{staticClass:"rate-container margin-top-24"},[_c('div',{staticClass:"body2 sub2"},[_vm._v("후기 "+_vm._s(_vm.allCount))]),_c('div',{staticClass:"flex-align",staticStyle:{"gap":"16px","margin-top":"16px"}},[_c('div',{staticClass:"flex-align",staticStyle:{"gap":"12px"}},[_c('star-rating',{attrs:{"rating":_vm.productRate,"readOnly":true,"active-color":"#FF6600","star-size":36,"increment":0.5,"padding":2,"star-points":[
              20,
              34,
              30,
              40,
              31,
              40,
              32,
              40,
              33,
              38,
              33,
              37,
              31,
              25,
              39,
              18,
              40,
              16,
              40,
              15,
              39,
              14,
              38,
              13,
              27,
              12,
              22,
              1,
              21,
              0,
              20,
              0,
              19,
              0,
              18,
              1,
              13,
              12,
              2,
              13,
              1,
              14,
              0,
              15,
              0,
              16,
              1,
              18,
              9,
              25,
              7,
              37,
              7,
              38,
              8,
              40,
              9,
              40,
              10,
              40,
              20,
              34 ],"show-rating":false}}),_c('div',{staticClass:"h4 main"},[_vm._v(" "+_vm._s(_vm.productRate)+" ")])],1)])]),_c('div',{staticClass:"review-tabs"},_vm._l((_vm.tabs),function(tab,idx){return _c('div',{key:'review-tab-mobile-' + idx,staticClass:"review-tab unselect",class:{
          active: _vm.activeTab === tab.value,
          'body4-bold main': _vm.activeTab === tab.value,
          'body4-medium sub3': _vm.activeTab !== tab.value,
        },on:{"click":function($event){return _vm.setActiveTab(tab.value)}}},[_c('div',[_vm._v(_vm._s(tab.name))]),_c('div',{staticClass:"count"},[_vm._v(_vm._s(tab.count))])])}),0),(_vm.computedReviews && _vm.computedReviews.length > 0)?[_vm._l((_vm.computedReviews),function(review,idx){return _c('div',{key:'theme-review-' + idx,staticClass:"item-review"},[_c('div',{staticClass:"flex-column",staticStyle:{"gap":"2px"}},[_c('div',{staticClass:"flex-align",staticStyle:{"gap":"4px"}},[_c('div',{staticClass:"body6 sub3"},[_vm._v(_vm._s(_vm.datesFormat(review.created_time, 'date_3')))]),_c('div',{staticClass:"line-bar"}),_c('div',{staticClass:"body6 sub3"},[_vm._v(_vm._s(_vm.authorFormat(review.author)))])]),_c('div',{staticClass:"flex-align",staticStyle:{"gap":"4px"}},[_c('star-rating',{attrs:{"rating":review.rate,"readOnly":true,"active-color":"#FF6600","star-size":16,"increment":0.5,"padding":1,"star-points":[
                20,
                34,
                30,
                40,
                31,
                40,
                32,
                40,
                33,
                38,
                33,
                37,
                31,
                25,
                39,
                18,
                40,
                16,
                40,
                15,
                39,
                14,
                38,
                13,
                27,
                12,
                22,
                1,
                21,
                0,
                20,
                0,
                19,
                0,
                18,
                1,
                13,
                12,
                2,
                13,
                1,
                14,
                0,
                15,
                0,
                16,
                1,
                18,
                9,
                25,
                7,
                37,
                7,
                38,
                8,
                40,
                9,
                40,
                10,
                40,
                20,
                34 ],"show-rating":false}}),_c('div',{staticClass:"body5-medium main"},[_vm._v(" "+_vm._s(review.rate)+" ")])],1)]),(review.imgs.length > 0)?_c('div',{staticClass:"flex-align drag-scroll margin-top-12"},_vm._l((review.imgs),function(img,idx){return _c('div',{key:'img-mobile-' + idx,staticStyle:{"display":"inline-block","margin-right":"4px"},on:{"click":function($event){return _vm.clickImg(review.imgs, idx)}}},[_c('e-image',{attrs:{"isBg":true,"isLazy":false,"img":img,"useThumb":true,"width":88,"useRatio":true,"imgRadius":8,"imageRates":{ ratio: '1:1' }}})],1)}),0):_vm._e(),(review.title !== '')?_c('div',{staticClass:"body5 sub3 margin-bottom-12"},[_vm._v(_vm._s(review.title))]):_vm._e(),_c('div',{staticClass:"body4 main"},[_vm._v(_vm._s(review.content))])])}),_c('lp-paginate',{staticClass:"padding-top-32",attrs:{"filtered":_vm.filtered,"unit":5},on:{"update:filtered":function($event){_vm.filtered=$event},"getData":_vm.getData}})]:_c('div',{staticClass:"empty-view"},[_vm._v(_vm._s(_vm.emptyMessage))])],2),_c('image-modal',{ref:"imgModal",attrs:{"imgs":_vm.selectedImgs,"idx":_vm.selectedImgIdx}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }